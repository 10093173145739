import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { getRoles } from "../../../../shared/util/services/auth/AuthService";
import Auth from "../../../../shared/components/Authentication/Auth";
import Login from "../../../../shared/components/Authentication/Login";
import Error from "../Error";
import { routeFactory } from "../../../config/roles";
import withAppLayout from "../../../../shared/components/withAppLayout";
import AuthWrapper from "../../../../shared/components/Authentication/AuthWrapper";
import SessionManager from "../../../../shared/util/services/auth/SessionManager";
import ErrorBoundary from "../../../../shared/components/common/ErrorBoundary";
import PropTypes from "prop-types";
import HelpPanelProvider from "../../../../shared/util/context/help/HelpContext";
import { helpDefinitions } from "../help/definitions";

const Routes = ({ setHideBanner, hideBanner }) => {
  const userRoles = getRoles();
  const routes = routeFactory(userRoles);
  let rootRedirect = "/login";

  if (routes.length >= 1) {
    rootRedirect = routes.filter((route) => route.main)[0].path;
  }

  useEffect(() => {
    const { pathname, search } = window.location;
    if (pathname !== "/auth" && pathname !== "/login") {
      SessionManager.setOriginUrl(`${pathname}${search}`);
    }
  }, []);

  return (
    <Switch>
      <Route exact path="/auth">
        <ErrorBoundary>
          <Auth />
        </ErrorBoundary>
      </Route>
      <Route exact path="/login">
        <ErrorBoundary>
          <Login />
        </ErrorBoundary>
      </Route>
      {routes.map((route, index) => {
        const Component = route.component;
        return (
          <Route exact path={route.path} key={index}>
            <ErrorBoundary>
              <AuthWrapper>
                <HelpPanelProvider definitions={helpDefinitions}>
                  <Component
                    setHideBanner={setHideBanner}
                    hideBanner={hideBanner}
                  />
                </HelpPanelProvider>
              </AuthWrapper>
            </ErrorBoundary>
          </Route>
        );
      })}
      <Route
        exact
        path="/error"
        render={(props) => withAppLayout({ component: <Error {...props} /> })()}
      />
      <Route
        path="*"
        component={() => (
          <AuthWrapper>
            <Redirect to={rootRedirect} />
          </AuthWrapper>
        )}
      />
    </Switch>
  );
};

Routes.propTypes = {
  setHideBanner: PropTypes.func,
  hideBanner: PropTypes.bool,
};

export default Routes;
