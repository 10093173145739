import React from "react";

import i18next from "external/i18n";

export const ModernizationServicesContent = () => (
  <>
    <p>
      {i18next.t(
        "help.eligibility.container.modernizationServices.content.modernizationServices.paragraph1"
      )}
    </p>
    <p>
      {i18next.t(
        "help.eligibility.container.modernizationServices.content.modernizationServices.paragraph2"
      )}
    </p>
  </>
);
