import React from "react";

import i18next from "external/i18n";

export const ProjectDetailsContent = () => {
  const details: Array<string> = i18next.t(
    "help.wizard.projectDetails.page.content.mapEligibility.content.details",
    { returnObjects: true }
  );
  return (
    <>
      <h2>
        {i18next.t(
          "help.wizard.projectDetails.page.content.mapEligibility.header.title"
        )}
      </h2>
      <p>
        {i18next.t(
          "help.wizard.projectDetails.page.content.mapEligibility.content.paragraph1"
        )}
        <ul>
          {details.map((detail, index) => (
            <li key={`detail-${index}`}>{detail}</li>
          ))}
        </ul>
      </p>
      <p>
        <strong>Important: </strong>{" "}
        {i18next.t(
          "help.wizard.projectDetails.page.content.mapEligibility.content.paragraph2"
        )}
      </p>
    </>
  );
};
