import React from "react";

import i18next from "external/i18n";

export const EligibilityContent = () => (
  <>
    <p>{i18next.t("help.eligibility.page.content.paragraph1")}</p>
    <h2>{i18next.t("help.eligibility.page.content.header1")}</h2>
    <p>{i18next.t("help.eligibility.page.content.paragraph2")}</p>
    <h2>{i18next.t("help.eligibility.page.content.header2")}</h2>
    <p>{i18next.t("help.eligibility.page.content.paragraph3")}</p>
  </>
);
