import Config from "config";

export const initPendo = ({
  userCognitoId,
  userRoles,
  pendoApiKey,
  accountId,
}) => {
  console.log(userCognitoId, userRoles, pendoApiKey, accountId);
  return (function (apiKey) {
    (function (p, e, n, d, o) {
      var v, w, x, y, z;
      o = p[d] = p[d] || {};
      o._q = o._q || [];
      v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? "unshift" : "push"](
                [m].concat([].slice.call(arguments, 0))
              );
            };
        })(v[w]);
      y = e.createElement(n);
      y.async = !0;
      y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, "script", "pendo");

    window.pendo.initialize({
      visitor: {
        // Required if user is logged in, default creates anonymous ID
        // internal metadata comes here
        // language setting
        id: userCognitoId,
        roles: userRoles,
      },
      account: {
        // way to group users, external -> partnerID, internal -> posix group?
        // optional, can have multiple account ID
        id: accountId,
      },
    });
  })(pendoApiKey);
};

export const invokePendoGuide = ({
  userCognitoId,
  userRoles,
  isExternal = true,
}) => {
  const apiKey = getApiKey(isExternal);
  if (apiKey) {
    initPendo({
      userCognitoId: userCognitoId,
      userRoles: userRoles,
      pendoApiKey: apiKey,
      accountId: Config.PENDO_PARAMS.externalAccountId,
    });
  }
};

const getApiKey = (isExternal) => {
  let apiKey = "";
  if (Config.PENDO_PARAMS) {
    apiKey = isExternal
      ? Config.PENDO_PARAMS.externalApiKey
      : Config.PENDO_PARAMS.internalApiKey;
  }
  return apiKey;
};
