import React from "react";
import { SupportingDocumentationContent } from "external/components/common/help/wizard/supporting-documentation/page/SupportingDocumentationContent";
import { EstimatesContent } from "external/components/common/help/wizard/supporting-documentation/container/EstimatesContent";
import { ScopeChecklistContent } from "external/components/common/help/wizard/supporting-documentation/container/ScopeChecklistContent";

export const SupportingDocumentationReviewContent = () => (
  <>
    <h2>Overview</h2>
    <SupportingDocumentationContent />
    <EstimatesContent />
    <h2>Partner scope checklist</h2>
    <ScopeChecklistContent />
  </>
);
