import React from "react";

import i18next from "external/i18n";

export const ProgressionContent = () => (
  <>
    <h2>
      {i18next.t(
        "help.details.content.progression.content.overview.header.title"
      )}
    </h2>
    <p>
      {i18next.t(
        "help.details.content.progression.content.overview.content.paragraph"
      )}
    </p>
    <h2>
      {i18next.t(
        "help.details.content.progression.content.stages.header.title"
      )}
    </h2>
    <p>
      <strong>Created: </strong>
      {i18next.t(
        "help.details.content.progression.content.stages.content.created"
      )}
    </p>
    <p>
      <strong>Business approval: </strong>
      {i18next.t(
        "help.details.content.progression.content.stages.content.businessApproval"
      )}
    </p>
    <p>
      <strong>Finance approval: </strong>
      {i18next.t(
        "help.details.content.progression.content.stages.content.financeApproval"
      )}
    </p>
    <p>
      <strong>Cash claim: </strong>
      {i18next.t(
        "help.details.content.progression.content.stages.content.cashClaim"
      )}
    </p>
    <p>
      <strong>Completed: </strong>
      {i18next.t(
        "help.details.content.progression.content.stages.content.completed"
      )}
    </p>
  </>
);
