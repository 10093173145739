import React from "react";
import Link from "@amzn/awsui-components-react/polaris/link";

import i18next from "external/i18n";
import { EligibilityContent } from "external/components/common/help/eligibility/page/EligibilityContent";
import { MigrationPhaseContent } from "external/components/common/help/eligibility/container/MigrationPhaseContent";
import { ModernizationServicesContent } from "external/components/common/help/eligibility/container/ModernizationServicesContent";
import { OpportunityContent } from "external/components/common/help/wizard/opportunity/OpportunityContent";
import { AWSAccountIdContent } from "external/components/common/help/wizard/project-details/container/AWSAccountIdContent";
import { FundingDetailsContent } from "external/components/common/help/wizard/funding-details/page/FundingDetailsContent";
import { SupportingDocumentationContent } from "external/components/common/help/wizard/supporting-documentation/page/SupportingDocumentationContent";
import { EstimatesContent } from "external/components/common/help/wizard/supporting-documentation/container/EstimatesContent";
import { ScopeChecklistContent } from "external/components/common/help/wizard/supporting-documentation/container/ScopeChecklistContent";
import { IHelpDefinitions } from "shared/util/context/help/types/HelpTypes";
import { CashClaimContent } from "external/components/common/help/wizard/review/CashClaimContent";
import { SupportingDocumentationReviewContent } from "external/components/common/help/wizard/review/SupportingDocumentationReviewContent";
import { ProgressionContent } from "external/components/common/help/details/progression/ProgressionContent";
import { HistoryContent } from "external/components/common/help/details/history/HistoryContent";
import { ActualClaimContent } from "external/components/common/help/claim/ActualClaimContent";
import { ActualContent } from "external/components/common/help/claim/container/ActualContent";
import { CustomerSignOffContent } from "external/components/common/help/claim/container/CustomerSignOffContent";
import { Icon } from "@amzn/awsui-components-react";
import { ProjectDetailsContent } from "external/components/common/help/wizard/project-details/page/ProjectDetailsContent";

export const helpDefinitions: IHelpDefinitions = {
  "eligibility.page": {
    header: <h2>{i18next.t("help.eligibility.page.header.title")}</h2>,
    content: <EligibilityContent />,
  },
  "eligibility.migrationPhase": {
    header: (
      <h2>
        {i18next.t("help.eligibility.container.migrationPhase.header.title")}
      </h2>
    ),
    content: <MigrationPhaseContent />,
  },
  "eligibility.modernizationServices": {
    header: (
      <h2>
        {i18next.t(
          "help.eligibility.container.modernizationServices.header.title"
        )}
      </h2>
    ),
    content: <ModernizationServicesContent />,
    footer: (
      <div>
        <h3>
          Learn more <Icon name="external" />
        </h3>
        <ul>
          <li>
            <Link href="https://partnercentral.awspartner.com/partnercentral2/s/resources?Id=0698W00000x4c3qQAA">
              AWS modernization pathways
            </Link>
          </li>
        </ul>
      </div>
    ),
  },
  "wizard.opportunity.page": {
    header: <h2>{i18next.t("help.wizard.opportunity.page.header.title")}</h2>,
    content: <OpportunityContent />,
    footer: (
      <div>
        <h3>
          Learn more <Icon name="external" />
        </h3>
        <ul>
          <li>
            <Link href="https://partnercentral.awspartner.com/partnercentral2/s/resources?Id=0698W00000yTD28QAG">
              MAP Program Guide
            </Link>
          </li>
        </ul>
      </div>
    ),
  },
  "wizard.projectDetails.page": {
    header: (
      <h2>{i18next.t("help.wizard.projectDetails.page.header.title")}</h2>
    ),
    content: <ProjectDetailsContent />,
  },
  "wizard.projectDetails.container.awsAccountId": {
    header: (
      <h2>
        {i18next.t(
          "help.wizard.projectDetails.container.content.awsAccountId.header.title"
        )}
      </h2>
    ),
    content: <AWSAccountIdContent />,
  },
  "wizard.fundingDetails.page": {
    header: (
      <h2>{i18next.t("help.wizard.fundingDetails.page.header.title")}</h2>
    ),
    content: <FundingDetailsContent />,
    footer: (
      <div>
        <h3>
          Learn more <Icon name="external" />
        </h3>
        <ul>
          <li>
            <Link href="https://partnercentral.awspartner.com/partnercentral2/s/resources?Id=0698W00000dyzKlQAI">
              Finance approval guide
            </Link>
          </li>
        </ul>
      </div>
    ),
  },
  "wizard.supportingDocumentation.page": {
    header: (
      <h2>
        {i18next.t("help.wizard.supportingDocumentation.page.header.title")}
      </h2>
    ),
    content: <SupportingDocumentationContent />,
  },
  "wizard.supportingDocumentation.container.estimates": {
    header: (
      <h2>
        {i18next.t(
          "help.wizard.supportingDocumentation.container.estimates.header.title"
        )}
      </h2>
    ),
    content: <EstimatesContent />,
    footer: (
      <div>
        <h3>
          Learn more <Icon name="external" />
        </h3>
        <ul>
          <li>
            <Link href="https://calculator.aws/#/">AWS Pricing Calculator</Link>
          </li>
        </ul>
      </div>
    ),
  },
  "wizard.supportingDocumentation.container.scopeChecklist": {
    header: (
      <h2>
        {i18next.t(
          "help.wizard.supportingDocumentation.container.scopeChecklist.header.title"
        )}
      </h2>
    ),
    content: <ScopeChecklistContent />,
    footer: (
      <div>
        <h3>
          Learn more <Icon name="external" />
        </h3>
        <ul>
          <li>
            <Link href="https://partnercentral.awspartner.com/partnercentral2/s/resources?Id=0698W00000yTjY6QAK">
              Partner scope checklist
            </Link>
          </li>
        </ul>
      </div>
    ),
  },
  "wizard.review.opportunity": {
    header: (
      <h2>
        {i18next.t("help.wizard.review.container.opportunity.header.title")}
      </h2>
    ),
    content: <OpportunityContent />,
  },
  "wizard.review.claim": {
    header: (
      <h2>{i18next.t("help.wizard.review.container.claim.header.title")}</h2>
    ),
    content: <CashClaimContent />,
  },
  "wizard.review.supportingDocumentation": {
    header: (
      <h2>
        {i18next.t("help.wizard.supportingDocumentation.page.header.title")}
      </h2>
    ),
    content: <SupportingDocumentationReviewContent />,
    footer: (
      <div>
        <h3>
          Learn more <Icon name="external" />
        </h3>
        <ul>
          <li>
            <Link href="https://calculator.aws/#/">AWS Pricing Calculator</Link>
          </li>
        </ul>
      </div>
    ),
  },
  "details.progression": {
    header: (
      <h2>{i18next.t("help.details.content.progression.header.title")}</h2>
    ),
    content: <ProgressionContent />,
  },
  "details.history": {
    header: <h2>{i18next.t("help.details.content.history.header.title")}</h2>,
    content: <HistoryContent />,
  },
  "claim.page": {
    header: <h2>{i18next.t("help.claim.page.header.title")}</h2>,
    content: <ActualClaimContent />,
    footer: (
      <div>
        <h3>
          Learn more <Icon name="external" />
        </h3>
        <ul>
          <li>
            <Link href="https://partnercentral.awspartner.com/partnercentral2/s/resources?Id=0698W00000x4MGKQA2">
              Customer sign-off template
            </Link>
          </li>
        </ul>
      </div>
    ),
  },
  "claim.container.actual": {
    header: <h2>{i18next.t("help.claim.content.actual.header.title")}</h2>,
    content: <ActualContent />,
  },
  "claim.container.customerSignOff": {
    header: (
      <h2>{i18next.t("help.claim.content.customerSignOff.header.title")}</h2>
    ),
    content: <CustomerSignOffContent />,
    footer: (
      <div>
        <h3>
          Learn more <Icon name="external" />
        </h3>
        <ul>
          <li>
            <Link href="https://partnercentral.awspartner.com/partnercentral2/s/resources?Id=0698W00000x4MGKQA2">
              Customer sign-off template
            </Link>
          </li>
        </ul>
      </div>
    ),
  },
};
