import React from "react";

import i18next from "external/i18n";

export const AWSAccountIdContent = () => (
  <>
    <p>
      {i18next.t(
        "help.wizard.projectDetails.container.content.awsAccountId.content.paragraph1"
      )}
    </p>
  </>
);
