import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./i18n";
import ErrorBoundary from "../shared/components/common/ErrorBoundary/ErrorBoundary";
import "@amzn/awsui-global-styles/polaris.css";
import "./index.css";
import "@amzn/node-js-apn-feedback-common-assets/style.css";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Router>
  </React.StrictMode>,

  document.getElementById("root")
);
