import React from "react";

import "./styles.css";
import { cookieConsent } from "../../../../shared/util/services/compliance/shortbread";

// Format and styling based on: https://w.amazon.com/bin/view/Shortbread/documentation/ux#HLEGALZONE
export const Footer = () => {
  const { shortbread } = cookieConsent({});

  const currentYear = new Date().getFullYear();

  return (
    <div id="f" className="footer">
      <div className="footer__links">
        <a className="footer__element" href="https://aws.amazon.com/privacy/">
          Privacy
        </a>
        <span className="footer__divider">|</span>
        <a className="footer__element" href="https://aws.amazon.com/terms/">
          Site Terms
        </a>
        <span className="footer__divider">|</span>
        <div
          id="footer__links_preferences"
          className="footer__element"
          onClick={() => {
            shortbread.customizeCookies();
          }}
        >
          Cookie preferences
        </div>
      </div>
      <span className="footer__divider">|</span>
      <div className="footer__copyright footer__element">
        &#169; {currentYear}, Amazon Web Services, Inc. or its affiliates. All
        rights reserved.
      </div>
    </div>
  );
};
