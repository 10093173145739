import React from "react";

import i18next from "external/i18n";

export const ScopeChecklistContent = () => (
  <>
    <p>
      {i18next.t(
        "help.wizard.supportingDocumentation.container.scopeChecklist.content.scopeChecklist.paragraph1"
      )}
    </p>
  </>
);
