import React from "react";

import i18next from "external/i18n";

export const OpportunityContent = () => {
  const criteria: Array<string> = i18next.t(
    "help.wizard.opportunity.page.content.opportunitySelection.content.criteria",
    { returnObjects: true }
  );

  return (
    <>
      <h2>
        {i18next.t(
          "help.wizard.opportunity.page.content.oneCustomer.header.title"
        )}
      </h2>
      <p>
        {i18next.t(
          "help.wizard.opportunity.page.content.oneCustomer.content.paragraph1"
        )}
      </p>
      <h2>
        {i18next.t(
          "help.wizard.opportunity.page.content.opportunitySelection.header.title"
        )}
      </h2>
      <p>
        {i18next.t(
          "help.wizard.opportunity.page.content.opportunitySelection.content.paragraph1"
        )}
      </p>
      <p>
        {i18next.t(
          "help.wizard.opportunity.page.content.opportunitySelection.content.paragraph2"
        )}
      </p>
      <p>
        {i18next.t(
          "help.wizard.opportunity.page.content.opportunitySelection.content.paragraph3"
        )}
      </p>
      <p>
        {i18next.t(
          "help.wizard.opportunity.page.content.opportunitySelection.content.paragraph4"
        )}
      </p>

      <ul>
        {criteria.map((item: string, index: number) => (
          <li key={`criteria-${index}`}>{item}</li>
        ))}
      </ul>
      <p>
        {i18next.t(
          "help.wizard.opportunity.page.content.opportunitySelection.content.paragraph5"
        )}
      </p>
    </>
  );
};
