import React from "react";

import i18next from "external/i18n";

export const FundingDetailsContent = () => (
  <>
    <h2>
      {i18next.t(
        "help.wizard.fundingDetails.page.content.financeApproval.header"
      )}
    </h2>
    <p>
      {i18next.t(
        "help.wizard.fundingDetails.page.content.financeApproval.paragraph1"
      )}
    </p>
  </>
);
