import React from "react";

import i18next from "external/i18n";

export const CashClaimContent = () => (
  <>
    <h2>
      {i18next.t("help.wizard.review.container.claim.content.overview.header")}
    </h2>
    <p>
      {i18next.t(
        "help.wizard.review.container.claim.content.overview.paragraph1"
      )}
    </p>
    <h2>
      {i18next.t(
        "help.wizard.review.container.claim.content.assessPhaseCashClaim.header"
      )}
    </h2>
    <p>
      {i18next.t(
        "help.wizard.review.container.claim.content.assessPhaseCashClaim.paragraph1"
      )}
    </p>
    <h2>
      {i18next.t(
        "help.wizard.review.container.claim.content.mobilizePhaseCashClaim.header"
      )}
    </h2>
    <p>
      {i18next.t(
        "help.wizard.review.container.claim.content.mobilizePhaseCashClaim.paragraph1"
      )}
    </p>
    <p>
      {i18next.t(
        "help.wizard.review.container.claim.content.mobilizePhaseCashClaim.paragraph2"
      )}
    </p>
  </>
);
