import React from "react";
import Link from "@amzn/awsui-components-react/polaris/link";

export const EstimatesContent = () => (
  <>
    <h2>Steps to retrieve an AWS Pricing Calculator estimate</h2>
    <ol>
      <li>
        Go to{" "}
        <Link external href="https://calculator.aws/#/">
          AWS Pricing Calculator
        </Link>
        .
      </li>
      <li>Create an estimate.</li>
      <li>
        Choose <strong>share</strong>.
      </li>
      <li>
        Copy and paste the link into the{" "}
        <strong>AWS Pricing Calculator estimates</strong> field.
      </li>
      <li>For multiple links, use a comma to separate each link.</li>
    </ol>
  </>
);
