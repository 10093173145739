import React from "react";

import i18next from "external/i18n";

export const MigrationPhaseContent = () => {
  const supportedFunding: Array<string> = i18next.t(
    "help.eligibility.container.migrationPhase.content.assessPhase.supportedFunding",
    { returnObjects: true }
  );
  return (
    <>
      <h2>
        {i18next.t(
          "help.eligibility.container.migrationPhase.content.assessPhase.header"
        )}
      </h2>
      <p>
        {i18next.t(
          "help.eligibility.container.migrationPhase.content.assessPhase.paragraph1"
        )}
        <ul>
          {supportedFunding.map((supported, index) => (
            <li key={`supported-${index}`}>{supported}</li>
          ))}
        </ul>
      </p>
      <p>
        {i18next.t(
          "help.eligibility.container.migrationPhase.content.assessPhase.paragraph2"
        )}
      </p>
      <h2>
        {i18next.t(
          "help.eligibility.container.migrationPhase.content.mobilizePhase.header"
        )}
      </h2>
      <p>
        {i18next.t(
          "help.eligibility.container.migrationPhase.content.mobilizePhase.paragraph1"
        )}
      </p>
    </>
  );
};
