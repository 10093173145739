import React from "react";

import i18next from "external/i18n";

export const SupportingDocumentationContent = () => (
  <>
    <p>
      {i18next.t(
        "help.wizard.supportingDocumentation.page.content.supportingDocumentation.paragraph1"
      )}
    </p>
    <p>
      {i18next.t(
        "help.wizard.supportingDocumentation.page.content.supportingDocumentation.paragraph2"
      )}
    </p>
  </>
);
