import { AWSCShortbread } from "@amzn/shortbread";
import { analytics } from "../../../../external/util/services/metrics/analytics";
import Cookies from "universal-cookie/lib";

export const RUM_SESSION_COOKIE_NAME = "cwr_s";
export const RUM_USER_COOKIE_NAME = "cwr_u";
export const RUM_CREDENTIAL_COOKIE_NAME = "cwr_c";

export const cookieConsent = ({ hostname = window.location.hostname }) => {
  const shortbread = AWSCShortbread({
    parent: document.getElementById("root"),
    domain: window.location.hostname === "localhost" ? "localhost" : hostname,
    onConsentChanged: async (c) => {
      if (c && !c["performance"]) {
        const cookies = new Cookies();
        cookies.remove(RUM_SESSION_COOKIE_NAME);
        cookies.remove(RUM_USER_COOKIE_NAME);
        analytics.allowCookies(false);
      } else {
        analytics.allowCookies(true);
      }
    },
    registry: {
      access_token: {
        category: "essential",
      },
      id_token: {
        category: "essential",
      },
      refresh_token: {
        category: "essential",
      },
      token_expiration: {
        category: "essential",
      },
      "origin-url": {
        category: "essential",
      },
      i18nextLng: {
        category: "essential",
      },
      [RUM_SESSION_COOKIE_NAME]: {
        category: "essential" | "performance",
      },
      [RUM_USER_COOKIE_NAME]: {
        category: "essential" | "performance",
      },
      [RUM_CREDENTIAL_COOKIE_NAME]: {
        category: "essential" | "performance",
      },
    },
  });
  shortbread.checkForCookieConsent();

  return { shortbread };
};
