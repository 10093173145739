import React, { Suspense, useEffect, useState } from "react";
import Routes from "./components/common/Routes";
import {
  getUserCognitoId,
  getFundrequestId,
  getRoles,
  getSpmsId,
} from "../shared/util/services/auth/AuthService";
import { logout } from "../shared/util/services/auth/AuthService";
import Loading from "../shared/components/common/Loading";
import { useLocation } from "react-router-dom";
import "./App.css";
import Config from "config";
import SessionManager from "../shared/util/services/auth/SessionManager";
import { getUsername, idpLogout } from "./util/services/auth/AuthService";
import TopNavBar from "../shared/components/common/TopNavBar";
import Footer from "./components/common/Footer";
import { analytics } from "./util/services/metrics/analytics";
import { launchApertureFeedbackWidget } from "@amzn/node-js-apn-feedback-common-assets";
import { buildMetadataList } from "shared/util/common/feedback";
import { invokePendoGuide } from "../shared/util/pendo/initPendo";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";
import { isFeatureEnabled } from "shared/util/services/features/FeatureFlagsService";

function App() {
  // this is a test...
  const { pathname, search } = useLocation();
  const [apertureForm, setApertureForm] = useState({});
  const [nonConsoleOptions, setNonConsoleOptions] = useState({});
  const [hideBanner, setHideBanner] = useState(true);

  const userId = getUserCognitoId() || "";
  const userRoles = getRoles() || [];
  const spmsId = getSpmsId() || "";

  useEffect(async () => {
    if (pathname !== "/auth" && pathname !== "/login") {
      SessionManager.setOriginUrl(`${pathname}${search}`);
    }
    if (
      Config.RUM_APP_ID &&
      Config.RUM_ROLE_ARN &&
      Config.RUM_IDPOOL_ID &&
      analytics
    ) {
      analytics.enable();
      analytics.addSessionAttributes({
        fundrequestId: getFundrequestId() || "",
        userCognitoId: userId,
      });
      analytics.recordPageView(pathname);
    }

    const apertureForm = Config.EXTERNAL_FEEDBACK_FORM;
    const apertureConfig = Config.APERTURE_WIDGET_CONFIG;

    if (apertureForm && apertureConfig) {
      const metadataList = buildMetadataList(spmsId, userId, userRoles);
      apertureForm.metadataList = metadataList;

      setApertureForm(apertureForm);
      setNonConsoleOptions(apertureConfig);
    }

    const pendo_external_enabled = await isFeatureEnabled(
      FEATURE_FLAG_ENUMS.PENDO_EXTERNAL_ENABLED
    );
    if (pendo_external_enabled) {
      invokePendoGuide({
        userCognitoId: userId,
        userRoles: userRoles,
      });
    }
  }, [pathname]);

  return (
    <div className="awsui">
      <TopNavBar
        utilities={[
          Object.keys(apertureForm).length > 0 && {
            type: "button",
            text: "Feedback",
            onClick: () =>
              launchApertureFeedbackWidget({
                // VoC provides this launcher function to launch Aperture widget
                apertureForm: apertureForm,
                nonConsoleOptions: nonConsoleOptions,
                onError: (err) => {
                  console.log(`Feedback button error: , ${err.message}`);
                },
              }),
          },
          {
            type: "menu-dropdown",
            text: getUsername(),
            iconName: "user-profile",
            onItemClick: ({ detail: { id } }) => {
              if (id === "signout") {
                logout();
                window.location.href = idpLogout();
              }
            },
            items: [
              {
                id: "signout",
                text: "Back to Partner Central",
              },
            ],
          },
        ]}
      />
      <Suspense fallback={<Loading />}>
        <Routes setHideBanner={setHideBanner} hideBanner={hideBanner} />
      </Suspense>
      <Footer />
    </div>
  );
}

export default App;
